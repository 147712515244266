import { FC } from 'react';

export const IssueCheck: FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='12' fill='white' />
      <path
        d='M8 11.9696L10.9425 15L16 10'
        stroke='#2A7BB8'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
