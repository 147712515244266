import { css } from '@emotion/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { FC } from 'react';
import { graphql } from 'gatsby';

import { HeadInner } from '../../components/Head';
import { PaildMdxProvider } from '../../components/PaildMdxProvider';
import { Layout } from '../../components/layout';
import { ArticleHeading } from '../../components/organisms/ArticleHeading';
import { UserstoryHeading } from '../../components/userstory/UserStoryHeading';
import { UserstoryIssue } from '../../components/userstory/UserstoryIssue';

type HeadProps = {
  data: GatsbyTypes.UserstoryTemplateQueryQuery;
};
export const Head: FC<HeadProps> = (props) => {
  const userstory = props.data.contentfulUserstory;
  const intervieweeImageFile = userstory?.intervieweeImage?.file;
  if (!userstory) {
    return null;
  }

  return (
    <HeadInner
      path={`/userstory/${userstory.slug}`}
      title={`${userstory.companyName}様の導入事例`}
      description={`${userstory.title}${userstory.companyName}様の事例をご紹介します。`}
      imageUrl={
        intervieweeImageFile?.url
          ? `https:${intervieweeImageFile.url}`
          : undefined
      }
      width={intervieweeImageFile?.details?.image?.width}
      height={intervieweeImageFile?.details?.image?.height}
    />
  );
};

const UserstoryTemplate: FC<{
  data: GatsbyTypes.UserstoryTemplateQueryQuery;
}> = (props) => {
  const userstory = props.data.contentfulUserstory;
  if (!userstory) return null;

  const links = [
    { name: '導入事例', path: '/userstory/' },
    {
      name: `${userstory.companyName}様の事例`,
      path: `/userstory/${userstory.slug}`,
    },
  ];

  return (
    <Layout links={links}>
      <ArticleHeading category='userstory' title={userstory.title ?? ''}>
        <UserstoryHeading
          intervieweeImage={userstory.intervieweeImage?.gatsbyImageData!}
          companyLogo={userstory.companyLogo?.gatsbyImageData!}
          companyName={userstory.companyName!}
          intervieweeNameList={userstory.intervieweeNameList!}
          companyEmployee={userstory.companyEmployee!}
          companyIndustry={userstory.companyIndustry!}
          categoryList={userstory.category!}
        />
      </ArticleHeading>
      <section css={IssueSectionStyle}>
        <UserstoryIssue issueList={userstory.issueList!} />
      </section>
      <article css={ArticleStyle}>
        <PaildMdxProvider category='userstory'>
          <MDXRenderer>{userstory.content?.childMdx?.body || ''}</MDXRenderer>
        </PaildMdxProvider>
      </article>
    </Layout>
  );
};

const IssueSectionStyle = css({
  width: '100%',
  maxWidth: 1120,
  display: 'flex',
  justifyContent: 'center',

  '@media(max-width: 1119px)': {
    padding: '0 14px',
  },
});

const ArticleStyle = css({
  width: '100%',
  maxWidth: 1120,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    padding: '0 17px',
  },
});

export const query = graphql`
  query UserstoryTemplateQuery($id: String) {
    contentfulUserstory(id: { eq: $id }) {
      id
      slug
      title
      companyLogo {
        title
        description
        gatsbyImageData(placeholder: NONE)
      }
      companyName
      companyBusiness
      companyEmployee
      companyIndustry
      category
      issueList
      intervieweeImage {
        title
        description
        gatsbyImageData(placeholder: NONE)
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      intervieweeNameList
      intervieweePosition
      intervieweeBackground {
        intervieweeBackground
      }
      intervieweePosition
      content {
        content
        childMdx {
          body
        }
      }
    }
  }
`;

export default UserstoryTemplate;
