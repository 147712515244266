import { css } from '@emotion/react';
import { FC } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { colors } from '../../theme/colors';

type Props = {
  intervieweeImage: IGatsbyImageData;
  companyLogo: IGatsbyImageData;
  companyName: string;
  intervieweeNameList: readonly (string | null)[];
  companyEmployee: string;
  companyIndustry: string;
  categoryList: readonly (string | null)[];
};

export const UserstoryHeading: FC<Props> = ({
  intervieweeImage,
  companyLogo,
  companyName,
  intervieweeNameList,
  companyEmployee,
  companyIndustry,
  categoryList,
}) => {
  return (
    <div css={UserstoryHeadingStyle}>
      <div css={UserstoryHeadingBackgroundStyle} />
      <div css={IntervieweeImageStyle}>
        <GatsbyImage
          image={intervieweeImage}
          alt={`${companyName}様の事例`}
          css={IntervieweeImageStyle}
        />
      </div>
      <div css={IntervieweeInfoStyle}>
        <div css={IntervieweeInfoWrapperStyle}>
          <div css={IntervieweeNameWrapperStyle}>
            <div css={CompanyLogoStyle}>
              <GatsbyImage
                image={companyLogo}
                alt={companyName}
                css={{ height: '100%' }}
                loading='eager'
                objectFit='contain'
              />
            </div>
            <p css={CompanyNameStyle}>{companyName}</p>
            <div css={IntervieweeNameStyle}>
              {intervieweeNameList.map((intervieweeName, index) => {
                return index > 0 ? (
                  <p key={index} css={{ marginLeft: 4 }}>
                    / {intervieweeName} 様
                  </p>
                ) : (
                  <p key={index}>{intervieweeName} 様</p>
                );
              })}
            </div>
          </div>
        </div>
        <div css={InfoWrapperStyle}>
          <div css={InfoStyle}>
            <p css={InfoLabelStyle}>従業員数</p>
            <p>{companyEmployee}</p>
          </div>
          <div css={InfoStyle}>
            <p css={InfoLabelStyle}>業種</p>
            <p css={InfoTagStyle}>{companyIndustry}</p>
          </div>
          <div css={InfoStyle}>
            <p css={InfoLabelStyle}>導入理由</p>
            <div css={CategoryWrapperStyle}>
              {categoryList.map((category, index) => {
                return (
                  <p key={index} css={InfoTagStyle}>
                    {category}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const UserstoryHeadingStyle = css({
  display: 'flex',
  width: '100%',
  position: 'relative',
  justifyContent: 'space-between',
  gap: 60,

  '@media(max-width: 1119px)': {
    gap: 20,
  },

  '@media(max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const UserstoryHeadingBackgroundStyle = css({
  position: 'absolute',
  width: 'calc(100% - 50px)',
  height: 'calc(100% - 50px)',
  padding: '50px 60px 70px 0',

  background: colors.secondary,
  borderRadius: 16,
  top: 50,
  right: 0,
  zIndex: -1,

  '@media(max-width: 1119px)': {
    top: 30,
  },

  '@media(max-width: 768px)': {
    top: 100,
    width: '100%',
    height: 'calc(100% - 100px)',
  },
});

const IntervieweeImageStyle = css({
  maxWidth: 520,
  borderRadius: 16,

  '@media(max-width: 768px)': {
    padding: '0 20px',
  },
});

const IntervieweeInfoStyle = css({
  minWidth: 275,
  padding: '0 60px 70px 0',
  flexGrow: 1,

  lineHeight: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 50,

  '@media(max-width: 1119px)': {
    gap: 30,
    padding: '0 40px 50px 0',
  },

  '@media(max-width: 768px)': {
    padding: '0 30px 60px',
  },
});

const IntervieweeInfoWrapperStyle = css({
  display: 'flex',
  gap: 27,

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 30,
    borderBottom: `1px solid #cccccc`,
  },
});

const CompanyLogoStyle = css({
  width: '100%',
  maxWidth: 500,
  height: 87,
  borderRadius: 16,
  border: `solid 2px ${colors.black}`,
  background: colors.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 12,
  marginBottom: 16,
});

const IntervieweeNameWrapperStyle = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  paddingTop: 26,

  '@media(max-width: 1119px)': {
    alignItems: 'center',
  },
});

const CompanyNameStyle = css({
  fontSize: 28,
  fontWeight: 700,
  lineHeight: 1,

  '@media(max-width: 1119px)': {
    fontSize: 22,
  },
});

const IntervieweeNameStyle = css({
  display: 'flex',
  fontSize: 16,
  fontWeight: 700,
});

const InfoWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 18,
});

const InfoStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: 30,

  '@media(max-width: 1119px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 12,
  },
});

const CategoryWrapperStyle = css({
  display: 'flex',
  gap: 8,
});

const InfoLabelStyle = css({
  fontSize: 16,
  fontWeight: 700,
  minWidth: 64,
});

const InfoTagStyle = css({
  width: 'fit-content',
  fontSize: 12,
  border: `2px solid ${colors.primary}`,
  padding: '6px 10px',
  borderRadius: 24,
  color: colors.primary,
  lineHeight: 1,
  background: colors.white,
});
