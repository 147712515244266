import { css } from '@emotion/react';
import { FC } from 'react';
import { colors } from '../../theme/colors';
import { IssueCheck } from '../icons/IssueCheck';

type Props = {
  issueList: readonly (string | null)[];
};

export const UserstoryIssue: FC<Props> = ({ issueList }) => {
  return (
    <div css={UserstoryIssueWrapperStyle}>
      <p css={UserstoryIssueTitleStyle}>課題・効果</p>
      <div css={UserstoryIssueListStyle}>
        {issueList.map((issue, index) => {
          return (
            <p key={index} css={UserstoryIssueStyle}>
              <span>
                <IssueCheck />
              </span>
              {issue}
            </p>
          );
        })}
      </div>
    </div>
  );
};
const UserstoryIssueWrapperStyle = css({
  display: 'flex',
  width: '100%',
  maxWidth: 1120,
  padding: '50px 80px',
  gap: 80,
  background: colors.grad.secondary,
  borderRadius: 16,
  fontWeight: 700,

  '@media(max-width: 1119px)': {
    maxWidth: 640,
    padding: '40px 30px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 40,
  },
});

const UserstoryIssueTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  color: colors.white,
  fontSize: 28,

  '@media(max-width: 1119px)': {
    fontSize: 22,
  },
});

const UserstoryIssueListStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

const UserstoryIssueStyle = css({
  color: colors.white,
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  fontSize: 20,
  fontWeight: 700,

  '@media(max-width: 1119px)': {
    fontSize: 16,
    alignItems: 'flex-start',
    lineHeight: 1.6,
  },
});
